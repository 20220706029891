$dark_blue : #183E58;
$dark_green : #2E7D32;
$dark_blue2 : #0975B6;

.container{
  border-radius: 8px;
  background-color: white;
  padding: 2% 3% 3% 3%;
  margin: 1%;
  box-shadow: 6px -5px 45px -12px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 6px -5px 45px -31px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 6px -5px 45px -12px rgba(0, 0, 0, 0.66)
}

.container_2{
  border-radius: 8px;
  background-color: white;
}

.save_button{
  background-color: $dark_blue2 !important;
  width: 90px !important;
  font-size: 10pt !important;
  height: 40px;
  border-radius: 8px !important;
  margin-top: 2%;
  font-weight: 700 !important;
}

.add_button{
  background-color: $dark_blue2 !important;
  width: 180px !important;
  font-size: 10pt !important;
  height: 40px;
  border-radius: 8px !important;
  margin-top: 2%;
  font-weight: 700 !important;
}

.table_head{
  color: #626262;
  background-color: #f1eeee;
  border-bottom: 2px solid #dee2e6;
  margin-top: 2%;
  margin-bottom: 1%;
}

.th_text{
  font-size: 12px;
  text-transform: uppercase;
}

.table{
  margin-top: 2%;
}

.buttonStatus{
  color: white !important;
  padding: 3% 4%;
  width: 95px;
  font-size: 12px;
  text-align: center;
  border-radius: 14px;
}

.statusDone{
  background-color: $dark_green !important;
}

.statusNotPaid{
  background-color: #EF6C00 !important;
}

.statusDelivered{
  background-color: #2196F3 !important;
}

.statusPacking{
  background-color: #9C27B0 !important;
}

.statusNoShow{
  background-color: #D32F2F !important;
}

.statusCancelled{
  background-color: black !important;
}

.date_picker{
  width: 100%;
  padding-left: 5%;
  height: 48px;
  border-radius: 7px !important;
  border: none !important;
  margin-bottom: 3%;
  border-color: white !important;
  background-color: #F7F7F7;
}

.inactive{
  background-color: #EF6C00 !important;
}

.verif{
  background-color: $dark_green !important;
  color: white !important;
  padding: 3% 4%;
  width: 105px;
  font-size: 12px;
  text-align: center;
  border-radius: 14px;
}

.notVerif{
  background-color: #F8D16E !important;
  color: black !important;
  padding: 3% 4% !important;
  width: 105px;
  font-size: 12px;
  text-align: center;
  border-radius: 14px;
}

.data_row{
  padding-top: 6px !important;
  font-size: 12px;
}

.detail{
  color: white !important;
  padding: 4% 4%;
  font-size: 12px;
  text-align: center;
  border-radius: 14px;
  width: 37px;
  background-color: $dark_blue2;
  animation: 0.5s;
  border: 1px solid transparent;
}

.detail:hover{
  background-color: white;
  color: #1b4460 !important;
  border: 1px solid #1b4460;
}

.no_underline:hover{
  text-decoration: none !important;
  color: black !important;
}

.cancel_button{
  color: $dark_blue2 !important;
  width: 90px !important;
  border: 2px solid $dark_blue2 ;
  font-size: 10pt !important;
  height: 40px;
  border-radius: 8px !important;
  margin-top: 2%;
  font-weight: 700 !important;
  background-color: white !important;
}

.save_button_2{
  background-color: $dark_blue2 !important;
  width: 170px !important;
  font-size: 10pt !important;
  height: 40px;
  border-radius: 8px !important;
  margin-top: 2%;
  font-weight: 700 !important;
}

.total_data{
  height: 36px;
  padding-top: 3%;
  margin-top: 2%;
  width: 71px;
  margin-bottom: 2%;
  background-color: white!important;
  color: grey !important;
  text-align: left !important;
}

.page_data{
  padding-top: 8px;
}

.field_form{
  height: 48px;
  border-radius: 7px !important;
  margin-bottom: 2%;
  width: 100% !important;
  color: grey !important;
  text-align: left !important;
  background-color: #F7F7F7;
  border-color: white !important;
}

.icon_search{
  border-radius: 7px 0px 0px 7px !important;
  border-right: none !important;
  height: 48px;
  background-color: #F7F7F7;
  border-color: white !important;
}

.field_search{
  height: 48px;
  border-radius: 0px 7px 7px 0px !important;
  border-left: none !important;
  margin-bottom: 3%;
  border-color: white !important;
  background-color: #F7F7F7;
}

.main_title{
  font-family: "PlusJakartaSans";
  font-size: 21px;
  font-weight: 800;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;
  color: #183E58;
  margin-bottom: 3%;
}
