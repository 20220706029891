$dark_blue : #183E58;
$dark_green : #096D6A;

.left_image {
  // background-image: url('../../../public/images/main.png');
  height: 100vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-color: #062E47; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.privPol{
  background-color: white;
  text-align: justify !important;
  padding: 5%;
  margin-top: 1%;
  box-shadow: 4px 1px 28px -24px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 4px 1px 28px -24px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 4px 1px 28px -24px rgba(0, 0, 0, 0.8);
}

.left_image_half{
  background-image: url('../../../public/images/main.png');
  height: 121vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  width: 25%;
  background-color: #062E47; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.separator{
  text-align: center;
  margin-bottom: 2%;
  margin-top: 1%;
  color: grey;
}

.field_title{
  font-size: 9pt;
}

.main_title{
  font-family: 'Inter';
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0px;
  color: $dark_blue;
  margin-bottom: 3%;
}

.sub_title{
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: $dark_blue;
  margin-bottom: 3%;
}

.arrow_back{
  padding-top: 0.54rem !important;
  padding-left: 1.54rem !important;
}

.main_title_forgot_password{
  font-family: 'Inter';
  font-size: 21px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;
  color: $dark_blue;
  margin-bottom: 5%;
}

.right_container{
  padding: 8% 7% 0% 7% !important;
}

.field_form{
  height: 42px;
  border-radius: 7px !important;
  background-color: #F7F7F7;
  font-size: 14.4px;
}

.field_form_password{
  height: 42px;
  border-radius: 7px 0px 0px 7px !important;
  font-size: 14.4px;
  background-color: #F7F7F7;
  margin-bottom: 0% !important;
}

.eye_container{
  background-color: white !important;
  border-radius: 0px 7px 7px 0px !important;
  height: 42px;
  cursor: pointer;
}

.field_container{
  padding: 0% 11%;
}

.login_button{
  border-radius: 8px;
  background: var(--Brand-Blue, #0975B6);
  width:  100%;
  height: 42px;
  font-size: 10pt !important;
  margin-top: 5%;
  padding: 11px 16px 10px 16px;
  font-weight: 700 !important;
}

.create_account{
  color: #1b4460 !important;
  border: 2px solid #1b4460 ;
  font-size: 10pt !important;
  height: 40px;
  width: 100%;
  border-radius: 24px !important;
  margin-top: 2%;
  font-weight: 700 !important;
  background-color: white !important;
}

.forgot_link{
  font-size: 9pt;
  font-weight: 700 !important;
  text-decoration-line: underline;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: right;
  color: $dark_green;
  cursor: pointer;
}

.label_right{
  margin-top: 1%;
  font-size: 9pt;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: right !important;
  margin-bottom: 0px !important;
}

.label_left{
  margin-top: 1%;
  font-size: 9pt;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left !important;
  margin-bottom: 0px !important;
}

.label_center{
  margin-top: 1%;
  font-size: 9pt;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center !important;
  margin-bottom: 0px !important;
}

.forgot_link_right{
  font-size: 9pt;
  font-weight: 700 !important;
  text-decoration-line: underline;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: right;
  color: $dark_green;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.forgot_link_left{
  font-size: 9pt;
  font-weight: 700 !important;
  text-decoration-line: underline;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: $dark_green;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.forgot_link_center{
  font-size: 9pt;
  font-weight: 700 !important;
  text-decoration-line: underline;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center !important;
  color: $dark_green;
  cursor: pointer;
  margin-bottom: 0px !important;
}